<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">绩效核算</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item label="时间：" class="timClas">
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changestartTime"
                  placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="科室：">
              <el-select v-model="typeValue" placeholder="请选择科室" clearable filterable @change="typeValueChange(typeValue)" class="xiangType">
                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center" :index="indexMethod">
          </el-table-column>
          <el-table-column
              prop="basePeriodTime"
              label="时间"
              align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="payableAmount" label="应发金额" align="center">
          </el-table-column>
          <el-table-column prop="actualAmount" label="实发金额" align="center">
          </el-table-column>
          <el-table-column prop="numOfPersonnel" label="在岗人数" align="center">
          </el-table-column>
          <el-table-column prop="averageBonus" label="平均奖金" align="center">
          </el-table-column>
          <el-table-column label="状态" align="center">
<!--            填报审核的状态：0 填报中 1 待核算 2 核算完成',-->
            <template slot-scope="scope">
              <div style="color: #81D3F8" v-if="scope.row.calculateStatus=='0'">填报中</div>
              <div style="color: #FACD91" v-if="scope.row.calculateStatus=='1'">待核算</div>
              <div
                  v-else-if="scope.row.calculateStatus=='2'"
                  class="activeStatus"
              >√</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template style="display: flex" slot-scope="scope">
<!--              待核算显示核算  另一个显示查看-->
              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.calculateStatus=='2'"
                  @click="lookClick(scope.row)"
              >查看</el-button>

              <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.calculateStatus=='1'"
                  @click="fillClick(scope.row)"
              >核算</el-button>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <el-dialog
        :title="fillTitle"
        :visible.sync="FillingVisible"
        width="1010px"
        top="1vh"
    >
      <el-form
          :model="Performance"
          ref="PerformanceRef"
          :rules="PerformanceRules"
          :inline="true"
          label-width="120px"
      >
        <div class="fillTwo">
        <div>
          <div class="tabBig">
            <div class="boxOne">
              科室名称
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              {{ Performance.name }}
            </div>
          </div>
              <div class="tabBig">
                <div class="boxOne">
                  工作量绩效
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.workload }}
                </div>
              </div>
          <div class="tabBig">
            <div class="boxOne">
              外院化验绩效
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              {{ Performance.outCheckWorkload }}
            </div>
          </div>
              <div class="tabBig">
                <div class="boxOne">
                  体检专项
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.checkup }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  两癌筛查
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.cancerScreen }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  医保基金奖励
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.medicalInsuranceFund }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  优质护理奖励
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.highQualityCare }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  质控奖罚
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.qualityControl }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  奖金补助
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.bonusSubsidy }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  体检专项(体检科)
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.otherSpecial }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  其他
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.other }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  阵痛分娩奖励
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.painfulDelivery }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  夜班费
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.nightDutyAllowance }}
                </div>
              </div>
              <div class="tabBig">
                <div class="boxOne">
                  人员列支
                </div>
                <div class="line"></div>
                <div class="boxTwo">
                  {{ Performance.personnelExpenditure }}
                </div>
              </div>
        </div>
        <div>
<!--          <div class="tabBig">-->
<!--            <div class="boxOne">-->
<!--              科室名称-->
<!--            </div>-->
<!--            <div class="line"></div>-->
<!--            <div class="boxTwo">-->
<!--              {{ Performance.name }}-->
<!--            </div>-->
<!--          </div>-->
          <el-form-item prop="numOfPersonnel" class="hosform">
            <div class="tabBig">
              <div class="boxOne">
                在岗人数
              </div>
              <div class="line"></div>
              <div class="boxTwo">
                {{ Performance.numOfPersonnel }}
<!--                <el-input type="number" :disabled="disabled" v-model="Performance.numOfPersonnel"></el-input>-->
              </div>
            </div>
          </el-form-item>
          <div class="tabBig">
            <div class="boxOne">
              考核得分
            </div>
            <div class="line"></div>
            <div class="boxTwo">
              {{ Performance.indexScore }}
            </div>
          </div>
            <div class="tabBig">
              <div class="boxOne">
                应发金额
              </div>
              <div class="line"></div>
              <div class="boxTwo">
                {{ Performance.actualAmount }}
              </div>
            </div>
            <div class="tabBig">
              <div class="boxOne">
                实发金额
              </div>
              <div class="line"></div>
              <div class="boxTwo">
                {{ Performance.actualAmount }}
              </div>
            </div>
            <div class="tabBig">
              <div class="boxOne">
                科室平均奖金
              </div>
              <div class="line"></div>
              <div class="boxTwo">
                {{ Performance.averageBonus }}
              </div>
            </div>
        </div>
        </div>
        <el-form-item v-if="btnShow" style="margin-top: 20px">
          <div class="btnSize">
            <el-button type="primary" @click="onsubmit"
            >核算</el-button
            >
          </div>
        </el-form-item>

        <div v-else class="btnSize" style="margin-top: 20px">
        <el-button  type="primary" @click="close">关闭</el-button>
        </div>

      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      typeValue:"",
      depoptions:[],
      startTime:'',
      endTime:"",
      disabled:false,
      btnShow:true,
      fillTitle:'',
      // 填报
      Performance: {
        // numOfPersonnel:'',
      },
      PerformanceRules: {
        // numOfPersonnel: [
        //   {
        //     required: true,
        //     message: "请输入在岗人数",
        //     trigger: "blur",
        //   },
        // ],
      },
      // 填报弹出框
      FillingVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      bascId:"",
      status1:"",
      status2:"",
      status3:"",
      status4:"",
      status5:""
    };
  },
  created() {
    this.getDateTime();
    // 科室数据查询
    this.findDepartment()
  },
  methods: {
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId")
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.getDepartmentNotPage();
    },
    changestartTime(val){
        this.startTime=val
        this.getDepartmentNotPage();
    },
    indexMethod(index) {
      let limitpage = this.pageSize; // 每页条数
      let curpage = this.pageNum; // 当前页码
      return index + 1 + (curpage - 1) * limitpage;
    },
    async fillClick(row) {
      this.bascId=row.id
      this.status1=row.status1
      this.status2=row.status2
      this.status3=row.status3
      this.status4=row.status4
      this.status5=row.status5
      this.fillTitle='科室绩效核算'
      this.btnShow=true
      this.FillingVisible=true
      this.disabled=false
      let data = {
        id: row.id
      };
      let { data: res } = await this.$axios.findById(data);
      // console.log(res)
      this.Performance=res
      this.Performance.name=row.name
    },
    async lookClick(row){
      this.fillTitle='查看科室绩效'
      this.btnShow=false
      this.FillingVisible=true
      this.disabled=true
      let data = {
        id: row.id
      };
      let { data: res } = await this.$axios.findById(data);
      // console.log(res)
      this.Performance=res
      this.Performance.name=row.name
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //  绩效核算
    async getDepartmentNotPage() {
      let data = {
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        pageType:'COUNT',//核算
        basePeriodTime:this.startTime,//时间
        departmentId:this.typeValue,
      };
      let { data: res } = await this.$axios.findCurrentYearAndMonthInformation(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onsubmit() {
      this.$refs.PerformanceRef.validate(async(valid) => {
        if (valid) {
          let data = _qs.stringify({
            id: this.bascId,//主键
            workload:this.Performance.workload,//工作量绩效
            checkup:this.Performance.checkup,//体检专项
            cancerScreen:this.Performance.cancerScreen,//两癌筛查
            medicalInsuranceFund:this.Performance.medicalInsuranceFund,//医保基金奖励
            highQualityCare:this.Performance.highQualityCare,//优质护理奖励
            qualityControl:this.Performance.qualityControl,//质控扣罚
            bonusSubsidy:this.Performance.bonusSubsidy,//奖金补助
            otherSpecial:this.Performance.otherSpecial,//体检专项(体检科)
            painfulDelivery:this.Performance.painfulDelivery,//阵痛分娩奖励
            nightDutyAllowance:this.Performance.nightDutyAllowance,//夜班费
            other:this.Performance.other,//其他
            personnelExpenditure:this.Performance.personnelExpenditure,//人员列支
            numOfPersonnel:this.Performance.numOfPersonnel,//在岗人数
            outCheckWorkload:this.Performance.outCheckWorkload,//外院化验工作量绩效
            calculateStatus:this.Performance.calculateStatus,//绩效核算状态
            indexScore:this.Performance.indexScore,//考核得分
            status1:this.status1,
            status2:this.status2,
            status3:this.status3,
            status4:this.status4,
            status5:this.status5
          });
          let { data: res } = await this.$axios.calculate(data);
          // console.log(res);
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getDepartmentNotPage()
            this.FillingVisible=false
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        }
      });
    },
    close(){
      this.FillingVisible=false
    }
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 400px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
  /deep/ .boxTwo .el-input__inner{
    text-align: center!important;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.checRev /deep/ .el-checkbox{
  margin-bottom: 20px;
}

.twoForm {
  float: right;
  display: flex;
}
/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.fillTwo{
  display: flex;
  justify-content: space-around;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnSize button{
  width: 120px;
}

// 取消开始时间和结束时间的×符号
/deep/ .twoForm .timClas .el-input__suffix{
  display: none!important;
}
</style>
